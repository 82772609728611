import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//React components imports
import Layout from "../components/Layout"
import SignUpForm from "../components/SignUpForm"
import Breadcrumbs from "../components/Breadcrumbs"

//Styled components imports
import Title from "../shared-styled-components/Title"

const SignUpPage = ({ location }) => {
  const intl = useIntl()

  return (
    <Layout
      subtitle={intl.formatMessage({
        id: "pages.registration.title",
      })}
    >
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: intl.formatMessage({
            id: "pages.registration.title",
          }),
        }}
      />
      <Title>
        {intl.formatMessage({
          id: "pages.registration.title",
        })}
      </Title>
      <SignUpForm />
    </Layout>
  )
}

export default SignUpPage
