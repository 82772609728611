import styled from "styled-components"

const Message = styled.div`
  position: absolute;
  top:  32px;
  right: 5px;
  width: 100%;
  color: red;
  text-align: center;
  
  @media (${props => props.theme.mediaQueries.tablet}) {
    top:  -22px;
  }
`

export default Message
