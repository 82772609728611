import styled from "styled-components"
import { Check2Circle } from "@styled-icons/bootstrap/Check2Circle"

const CheckIcon = styled(Check2Circle)`
  fill: ${props=>props.theme.colors.green};
  margin-right: 8px;
  margin-bottom: 2px;

`

export default CheckIcon
