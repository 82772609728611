import React, { useState } from "react"
import { navigate } from "gatsby"
import { useIntl } from "gatsby-plugin-react-intl"
import { createShopifyCustomer } from "../../lib/api"

//Styled components imports
import Wrapper from "./Wrapper"
import Message from "./Message"
import InputWrapper from "./InputWrapper"
import Text from "./Text"
import InputField from "../../shared-styled-components/InputField"
import Button from "../../shared-styled-components/Button"
import ErrorMessage from "../../shared-styled-components/ErrorMessage"
import SuccessfulMessage from "../../shared-styled-components/SuccessfulMessage"
import CheckIcon from "../../shared-styled-components/CheckIcon"

const initState = {
  email: "",
  password: "",
  confirmPassword: "",
}

const SignUpForm = () => {
  const [formValues, setFormValues] = useState(initState)
  const [errors, setErrors] = useState({})
  const [successful, setSuccessful] = useState(false)
  const intl = useIntl()

  const validateForm = () => {
    let newErrors = {}
    if (formValues.email === "") {
      newErrors = {
        email: intl.formatMessage({
          id: "sign_up_form.email_empty",
        }),
      }
    }

    if (formValues.password === "") {
      newErrors = {
        ...newErrors,
        password: intl.formatMessage({
          id: "sign_up_form.password_empty",
        }),
      }
    } else {
      if (formValues.password !== formValues.confirmPassword) {
        newErrors = {
          ...newErrors,
          confirmPassword: intl.formatMessage({
            id: "sign_up_form.passwords_do_not_match",
          }),
        }
      }
    }

    setErrors({ ...newErrors })
    return !Object.keys(newErrors).length
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (validateForm()) {
      const res = await createShopifyCustomer({
        email: formValues.email,
        password: formValues.password,
      })

      if (res.status === "success") {
        setFormValues(initState)
        setErrors({})
        setSuccessful(true)
        navigate("/")
      } else {
        setErrors({ general: res.message })
      }
    }
  }

  const handleChange = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  return (
    <Wrapper>
      {errors.general && <Message>{errors.general}</Message>}
      {!successful ? (
        <>
          <div>
            {intl.formatMessage({
              id: "sign_up_form.personal_info",
            })}
          </div>
          <InputWrapper style={{ marginTop: "25px" }}>
            <label htmlFor="email">
              {intl.formatMessage({
                id: "sign_up_form.email",
              })}
            </label>
            <div style={{ position: "relative" }}>
              <InputField
                name="email"
                type="email"
                value={formValues.email}
                onChange={handleChange}
              />
              {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
            </div>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="password">
              {intl.formatMessage({
                id: "sign_up_form.password",
              })}
            </label>
            <div style={{ position: "relative" }}>
              <InputField
                type="password"
                name="password"
                value={formValues.password}
                onChange={handleChange}
              />
              {errors.password && (
                <ErrorMessage>{errors.password}</ErrorMessage>
              )}
            </div>
          </InputWrapper>
          <InputWrapper>
            <label htmlFor="confirmPassword">
              {intl.formatMessage({
                id: "sign_up_form.password_verify",
              })}
            </label>
            <div style={{ position: "relative" }}>
              <InputField
                type="password"
                name="confirmPassword"
                value={formValues.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && (
                <ErrorMessage>{errors.confirmPassword}</ErrorMessage>
              )}
            </div>
          </InputWrapper>
          <>
            <div style={{ marginTop: "30px" }}>
              {intl.formatMessage({
                id: "sign_up_form.confirmation_title",
              })}
            </div>
            <Text>
              {intl.formatMessage({
                id: "sign_up_form.confirmation",
              })}
            </Text>
            <Button black onClick={handleSubmit}>
              {intl.formatMessage({
                id: "sign_up_form.register",
              })}
            </Button>
          </>
        </>
      ) : (
        <SuccessfulMessage>
          <CheckIcon size="36" />
          {intl.formatMessage({
            id: "sign_up_form.registeration_successfull",
          })}
        </SuccessfulMessage>
      )}
    </Wrapper>
  )
}

export default SignUpForm
