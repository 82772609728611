import styled from "styled-components"

const InputWrapper = styled.div`
  display: grid;
  padding: 10px 0 15px;
  align-items: center;
  position: relative;

  label {
    font-size: 20px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: repeat(2, 250px);
  }
`

export default InputWrapper
